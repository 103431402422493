/*
 *
 * TR INSTRUMENTS
 * Author: Martin Winkler
 * url: http://www.martinwinkler.cz/
 *
 */

var sliders = [];

/****************************************************************************************************************************************************************************************
DOCUMENT READY
****************************************************************************************************************************************************************************************/

jQuery(document).ready(function ($) {

	/**********************************************
	VARIOUS
	**********************************************/

	// Scrollování ke kotvě
	$('.to-anchor').scrollToAnchor();

	// Show/hide
	$('.sh-trig').toggler();

	$('.sh-trig-hide').toggler({
		hideTrigger: true
	});

	// Scrollspy
	$('.scrollspy').scrollSpy();

	// Tabs
	$('.tabs').liteTabs();

	// Responsive tables
	$('.entry table').wrap('<div class="table-wrap"></div>');

	// Lng switch
	$('.lng .current').on('click', function () {
		if ($('.lng').hasClass('active')) {
			$('.lng').removeClass('active');
		} else {
			$('.lng').addClass('active');
		}
		return false;
	});

	// Fix trigger
	$('.fix-trigger').on('click', function () {
		($(this).parent().toggleClass('active'));
		return false;
	});

	// Header search
	$('.li-search-trig').on('click', function () {
		if (!$('.header').hasClass('search-opened')) {
			$('.header').addClass('search-opened');
		} else {
			$('.header').removeClass('search-opened');
		}
		return false;
	});

	/**********************************************
	MEGAMENU
	**********************************************/

	$('.megamenu-trigger').on('mouseover', function () {
		var id = $(this).attr('data-id');

		$('.megamenu-trigger').removeClass('activated');

		$('[data-id="' + id + '"').addClass('activated');
	});

	$('.megamenu-trigger').on('mouseout', function () {
		$('.megamenu-trigger').removeClass('activated');
	});

	/**********************************************
	RANGE SLIDER
	**********************************************/
	$('.range-slider').each(function () {
		var rangeSlider = $(this);
		var id = rangeSlider.attr('id');
		var min = rangeSlider.data('min');
		var max = rangeSlider.data('max');
		var unit = rangeSlider.data('unit');
		var step = rangeSlider.data('step');
		var start = rangeSlider.data('start');
		var end = rangeSlider.data('end');
		var slider = document.getElementById(id);

		noUiSlider.create(slider, {
			start: [start, end],
			connect: true,
			step: step,
			range: {
				'min': min,
				'max': max
			},
			tooltips: [
				wNumb({
					decimals: 0,
					thousand: '.',
					// suffix: ' Kč'
				}),
				wNumb({
					decimals: 0,
					thousand: '.',
					// suffix: ' Kč'
				})
			],
			format: wNumb({
				decimals: 0
			})
		});

		// Display the slider value and how far the handle moved
		// from the left edge of the slider.
		slider.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
			// rangeSlider.closest('.range-slider-wrapper').find('.values').html(values[0] + ' - ' + values[1] + ' ' + unit);
			$('#' + id + 'Od').val(values[0]);
			$('#' + id + 'Do').val(values[1]);
			if (values[0] == min) {
				$('#' + id + 'Od').val(null);
			}
			if (values[1] == max) {
				$('#' + id + 'Do').val(null);
			}

		});
	});
	// if ($('.range-slider').length > 0) {
	// 	$('.range-slider').each(function() {
	// 		var id = $(this).attr('id');
	// 		var min = $(this).data('min');
	// 		var max = $(this).data('max');
	// 		var slider = document.getElementById(id);
	//
	// 		noUiSlider.create(slider, {
	// 	    start: [min, max],
	// 	    //tooltips: true,
	// 	    tooltips: [
	// 	    	wNumb({
	// 	    		decimals: 0,
	// 	    		thousand: '.',
	// 	    		suffix: ' Kč'
	// 	    	}),
	// 	    	wNumb({
	// 	    		decimals: 0,
	// 	    		thousand: '.',
	// 	    		suffix: ' Kč'
	// 	    	})
	// 	    ],
	// 	    connect: true,
	// 	    step: 1,
	// 	    range: {
	// 	      'min': min,
	// 	      'max': max
	// 	    },
	// 	    /*format: wNumb({
	//         decimals: 0
	//     	})*/
	// 		});
	// 	});
	// }

	/**********************************************
	SEARCH & PLACEHOLDER
	**********************************************/

	// Clear placeholder
	$(function () {
		var input = $('input[type="text"], textarea');

		input.each(function () {
			var placeholder = $(this).attr('placeholder');

			$(this).on('focus', function () {
				$(this).attr('placeholder', '');
			});

			$(this).on('blur', function () {
				$(this).attr('placeholder', placeholder);
			});
		});
	});

	// Form search
	$('.form-search input').on('focus', function () {
		$(this).closest('form').addClass('active');
	});

	$('.form-search input').on('blur', function () {
		if ($(this).val().length == 0) {
			$(this).closest('form').removeClass('active');
		}
	});

	// Search suggesting
	$('[data-purpose="search-header"]').on('keyup', function () {
		var searchInputVal = $(this).val();

		if (searchInputVal.length > 0) {
			$('[data-purpose="dropdown-search"]').addClass('active');
			$('body').addClass('searching');
		} else {
			$('[data-purpose="dropdown-search"]').removeClass('active');
			$('body').removeClass('searching');
		}
	});

	$('[data-purpose="search"]').on('keyup', function () {
		var searchInputVal = $(this).val();
		var id = $(this).attr("data-target");

		if (searchInputVal.length > 0) {
			$('#'+ id).addClass('active');
			$('body').addClass('searching');
		} else {
			$('#'+ id).removeClass('active');
			$('body').removeClass('searching');
		}

		if ($(this).attr("data-recount") == "true") {
			recount($(this), id);
		}
	});

	$(window).on('resize', function () {
		var el = $('[data-purpose="search"][data-recount="true"]');
		var id = $(el).attr("data-target");
		recount($(el), id);
	});

	function recount(el, target) {
		var dd = $('#'+ target).parent();

        if (!dd.get(0) && !$(el).get(0)) {
            return;
        }

		var ddtop = dd.get(0).getBoundingClientRect().top;
		var inputtop = $(el).get(0).getBoundingClientRect().top;
		$('#'+ target).css({top: (inputtop-ddtop+$(el).outerHeight(true) + 14) + "px"});
	}

	/**********************************************
	PRODUCTS
	**********************************************/

	// Category
	$('.category-list .item-inner').on('click', function () {
		var trigger = $(this);
		var wrapper = trigger.closest('.category-list');
		var items = $('.item', wrapper);
		var item = trigger.closest('.item');
		var id = trigger.data('id');
		var content = $('#' + id);

		if (!item.hasClass('active')) {
			items.removeClass('active');
			item.addClass('active');
			content.addClass('active');
		} else {
			item.removeClass('active');
			content.removeClass('active');
		}
	});

	// Product nav
	$('.product-nav h3').on('click', function () {
		var trigger = $(this);
		var nav = trigger.closest('.product-nav');

		if (!nav.hasClass('active')) {
			nav.addClass('active');
		} else {
			nav.removeClass('active');
		}
	});

	/**********************************************
	MODAL BOX
	**********************************************/

	$(function () {
		var trigger = $('.modal-trigger');
		var close = $('.modal-close');

		trigger.each(function (index, element) {
			var id = element.hash;
			var modal = $(id);

			$(element).on('click', function () {
				modal.addClass('active');
				$('body').addClass('modal-opened');
			});
		});

		// Close link
		close.on('click', function () {
			$('.modal').removeClass('active');
			$('body').removeClass('modal-opened');
		});

		// Overlay click
		$(window).on('click', function (e) {
			$('.modal-box-wrap').each(function (index, element) {
				if (e.target === element) {
					$('.modal').removeClass('active');
					$('body').removeClass('modal-opened');
				}
			});
		});

		// Escape close
		$(window).on('keyup', function (e) {
			if (e.keyCode === 27) {
				$('.modal').removeClass('active');
				$('body').removeClass('modal-opened');
			}
		});
	});

	/**********************************************
	AJAX FORM
	**********************************************/

	var ajaxContent = $('#form-ajax-content');
	var siteUrl = window.location.href;

	$('body').delegate('.form-ajax input, .form-ajax select', 'change', function (e) {
		var parameters = $(this).closest('form').serialize(); // Get form parameters
		var url = window.location.pathname + '?' + parameters;

		window.history.replaceState(null, null, url); // Change url

		if (!url) {
			return;
		}
		url = url + " #form-ajax-content > .inner";
		ajaxContent.addClass('loading').load(url, function () {
			ajaxContent.removeClass('loading');

			$(window).trigger('filterLoad');
		});
	});

	/**********************************************
	COOKIEBAR
	**********************************************/

	/*
	var webCookie = "website"+"Cookie" // přepsat dle domény
	var webCookiePrepend = "body" // prvek kde se má vyobrazit

	function SetCookie(cookieName,cookieValue,nDays) {
		  var today = new Date();
		  var expire = new Date();
		  if (nDays==null || nDays==0) nDays=1;
		  expire.setTime(today.getTime() + 3600000*24*nDays);
		  document.cookie = cookieName+"="+escape(cookieValue) + ";expires="+expire.toGMTString();
	}

	function ReadCookie(cookieName) {
		  var theCookie=" "+document.cookie;
		  var ind=theCookie.indexOf(" "+cookieName+"=");
		  if (ind==-1) ind=theCookie.indexOf(";"+cookieName+"=");
		  if (ind==-1 || cookieName=="") return "";
		  var ind1=theCookie.indexOf(";",ind+1);
		  if (ind1==-1) ind1=theCookie.length;
		  return unescape(theCookie.substring(ind+cookieName.length+2,ind1));
	}

	function DeleteCookie(cookieName) {
	  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	var cookieisok = ""+ReadCookie(webCookie);
	var testCookie = "";
	SetCookie("testCookie","ok",365);
	var testCookie = ""+ReadCookie('testCookie'); // test cookies availability

	$(document).ready(function(){
		if(cookieisok != "ok" && testCookie == "ok") {
			$(webCookiePrepend).prepend("<div class='cookie-notice'>Pro účely analýzy návštěvnosti používáme nástroj Google Analytics, který využívá soubory cookies. Předpokládáme, že s použitím cookies souhlasíte. <a href='javascript:;' class='cookie-button'>SOUHLASÍM</a></div>")
		}
		$('.cookie-button').click(function(){
			$(".cookie-notice").fadeOut(300);
			SetCookie(webCookie,"ok",365);
		});
	});
	*/

	/**********************************************
	CONTACT FORM 7
	**********************************************/

	// Nascrolluje na chybovou hlášku
	document.addEventListener('wpcf7invalid', function (event) {
		$('html:not(:animated),body:not(:animated)').animate({ scrollTop: $('.wpcf7-form').offset().top - 20 - $('.header').height() }, 1000);
	}, false);

	// Nascrolluje na success hlášku
	document.addEventListener('wpcf7mailsent', function (event) {
		$('html:not(:animated),body:not(:animated)').animate({ scrollTop: $('.wpcf7-form').offset().top - 20 - $('.header').height() }, 1000);

		// Google
		$('form.form-contact').append("<script> gtag('event', 'conversion', {'send_to': 'AW-663704445/qfZoCKu548gBEP2mvbwC'}); </script>");

		// Sklik
		$('form.form-contact').append('<iframe width="119" height="22" frameborder="0" scrolling="no" src="//c.imedia.cz/checkConversion?c=100060627&amp;color=ffffff&amp;v="></iframe>');
	}, false);

	/**********************************************
	FIXER
	**********************************************/

	$(window).on('load scroll', function () {
		if ($('.fixer').length) {
			$('.fixer-start').each(function () {
				var scrollTop = $(window).scrollTop();
				var winH = $(window).height();
				var fixElWrapper = $(this);
				var fixEl = fixElWrapper.find('.fixer');
				var offset = 0;
				if (fixEl.attr('data-offset')) {
					var offset = fixEl.data('offset');
				}

				var fixElWrapperTop = fixElWrapper.offset().top + offset;

				var fixElHeight = fixEl.height();
				var end = $('.fixer-end').height() + $('.fixer-end').offset().top - fixEl.height();

				// Top panel
				if (scrollTop > fixElWrapperTop) {
					fixEl.addClass('fixed');

					// Hide in the end
					if (scrollTop > end) {
						fixEl.fadeOut();
					}
					else {
						fixEl.fadeIn();
					}
				}
				else {
					fixEl.removeClass('fixed');
				}
			});
		}
	});

	/**********************************************
	MOBILE NAV
	**********************************************/

	$(function () {
		var mobileNav = $('.mobile-nav');
		var mobileNavOverlay = $('.mobile-nav-overlay');

		function showNav() {
			mobileNav.addClass('active');
			mobileNavOverlay.fadeIn();
			$('body').css({ 'overflow': 'hidden' });
			$('.wrap').addClass('active');
			$('.nav-btn').addClass('active');
		}

		function hideNav() {
			mobileNav.removeClass('active');
			mobileNavOverlay.fadeOut();
			$('body').css({ 'overflow': 'auto' });
			$('.wrap').removeClass('active');
			$('.nav-btn').removeClass('active');
		}

		$('.nav-btn').click(function () {
			if (!mobileNav.hasClass('active')) {
				showNav();
			}
			else {
				hideNav();
			}
			return false;
		});

		// Mobile nav close
		$('.mobile-nav .close').click(function () {
			hideNav();
		});

		// Mobile nav overlay close
		$('.mobile-nav-overlay').click(function () {
			hideNav();
		});

		$('.mobile-nav li a').each(function () {
			if ($(this).siblings('ul').length > 0) {
				$(this).append('<span class="more"></span>');
			}
		});

		// Mobile nav accordion
		$('.mobile-nav li .more').click(function () {
			var link = $(this).closest('li');
			var ul = link.first('ul');

			if (ul.length > 0) {
				if (!ul.hasClass('active')) {
					link.addClass('active');
					ul.addClass('active');
				} else {
					link.removeClass('active');
					ul.removeClass('active');
				}
			}

			return false;
		});
	});

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/

	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

	/**********************************************
  SIMPLE LIGHTBOX
  **********************************************/

	// Simple lightbox
	new SimpleLightbox({
		elements: ".fancybox,a[href$='.jpg'],a[href$='.jpeg'],a[href$='.png'],a[href$='.gif'],[data-fancybox='gallery'],[data-purpose='play']"
	});

	/**********************************************
	FANCYBOX
	**********************************************/

	if ($.fn.fancybox) {
		var fancyboxOptions = {
			loop: true,
			buttons: [
				// 'slideShow',
				// 'fullScreen',
				// 'thumbs',
				// 'share',
				// 'download',
				// 'zoom',
				'close'
			],
			thumbs: {
				autoStart: false,
				hideOnClose: true,
				parentEl: '.fancybox-container',
				axis: 'x'
			},
			afterLoad: function () {
				$(window).trigger('modalOpen');
			}
		}

		// Standard fancybox
		$(".fancybox, a[href$='.jpg'],a[href$='.jpeg'],a[href$='.png'],a[href$='.gif'],[data-fancybox='gallery']").fancybox(fancyboxOptions);

		// Modal box
		$('.modal-trigger').fancybox(fancyboxOptions);

		// Modal box after ajax load
		$(window).on('load filterLoad', function () {
			$('#form-ajax-content .modal-trigger').fancybox(fancyboxOptions);
		});
	}

	/**********************************************
	SLIDERS
	**********************************************/

	// Sudo slider
	if ($.fn.sudoSlider) {

		// Item slider
		$('.item-slider').each(function (index) {
			var id = $(this).attr('id');
			sliders[id] = [];
			var el = $(this);

			el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = 1;
			el.attr('data-slides-desktop') ? sliders[id]['slideNumDesktop'] = el.attr('data-slides-desktop') : sliders[id]['slideNumDesktop'] = sliders[id]['slideNum'];
			el.attr('data-slides-tablet') ? sliders[id]['slideNumTablet'] = el.attr('data-slides-tablet') : sliders[id]['slideNumTablet'] = sliders[id]['slideNumDesktop'];
			el.attr('data-slides-phablet') ? sliders[id]['slideNumPhablet'] = el.attr('data-slides-phablet') : sliders[id]['slideNumPhablet'] = sliders[id]['slideNumTablet'];
			el.attr('data-slides-mobile') ? sliders[id]['slideNumMobile'] = el.attr('data-slides-mobile') : sliders[id]['slideNumMobile'] = sliders[id]['slideNumPhablet'];
			el.attr('data-auto') ? sliders[id]['slideAuto'] = el.data('auto') : sliders[id]['slideAuto'] = true;
			el.attr('data-nav') ? sliders[id]['slideNav'] = el.data('nav') : sliders[id]['slideNav'] = true;
			el.attr('data-numeric') ? sliders[id]['slideNumeric'] = el.data('numeric') : sliders[id]['slideNumeric'] = true;
			el.attr('data-continuous') ? sliders[id]['slideContinuous'] = el.data('continuous') : sliders[id]['slideContinuous'] = true;
			el.attr('data-speed') ? sliders[id]['slideSpeed'] = el.data('speed') : sliders[id]['slideSpeed'] = 250;
			el.attr('data-pause') ? sliders[id]['slidePause'] = el.data('pause') : sliders[id]['slidePause'] = 5000;
			el.attr('data-effect') ? sliders[id]['slideEffect'] = el.data('effect') : sliders[id]['slideEffect'] = 'fade';
			el.attr('data-customlink') ? sliders[id]['slideCustomlink'] = el.data('customlink') : sliders[id]['slideCustomlink'] = 'a.customLink';

			var panelNum = el.find('.panel').length;

			//if (panelNum > sliders[id]['slideNum']) {
			sliders[id]['slider'] = el.find('.slider').sudoSlider({
				effect: sliders[id]['slideEffect'],
				vertical: false,
				auto: sliders[id]['slideAuto'],
				autowidth: false,
				continuous: sliders[id]['slideContinuous'],
				prevNext: sliders[id]['slideNav'],
				numeric: sliders[id]['slideNumeric'],
				pause: sliders[id]['slidePause'],
				slideCount: sliders[id]['slideNum'],
				speed: sliders[id]['slideSpeed'],
				responsive: true,
				customLink: sliders[id]['slideCustomlink'],
				controlsAttr: 'class="slider-nav"',
				prevHtml: '<a href="#" class="prev" aria-label="Předchozí"><svg class="icon icon-prev"><use xlink:href="/static/svg/svg-sprite.svg#icon-prev" /></svg></a>',
				nextHtml: '<a href="#" class="next" aria-label="Další"><svg class="icon icon-next"><use xlink:href="/static/svg/svg-sprite.svg#icon-next" /></svg></a>',
				initCallback: function () {
					//sliders[id].slider.closest('.item-slider').addClass('active');
				}
			});
			//}
		});

		$(window).on('load resizeEnd', function () {
			for (var id in sliders) {
				if (!sliders.hasOwnProperty(id))
					continue;

				//if ($(sliders[id]['slider']).closest('.item-slider').hasClass('active')) {
				var width = $(window).width();
				var orgSlideCount = sliders[id]['slider'].getOption("slideCount");
				var slideCount;

				if (width >= 1200) {
					slideCount = sliders[id]['slideNum']; // 1200 - 1920
				}
				else if (width >= 992) {
					slideCount = sliders[id]['slideNumDesktop']; // 992 - 1199
				}
				else if (width >= 768) {
					slideCount = sliders[id]['slideNumTablet']; // 768 - 991
				}
				else if (width >= 576) {
					slideCount = sliders[id]['slideNumPhablet']; // 576 - 768
				}
				else {
					slideCount = sliders[id]['slideNumMobile']; // 0 - 575
				}
				if (slideCount != orgSlideCount) {
					sliders[id]['slider'].setOption("slideCount", slideCount);
				}

				// Show/hide nav
				var slider = $(sliders[id]['slider']).closest('.item-slider');
				var slideCount = sliders[id]['slider'].getOption('slideCount');
				var slides = $('.panel', slider).length;

				if (slides <= slideCount) {
					slider.addClass('hide-nav');
				}
				else {
					slider.removeClass('hide-nav');
				}

				sliders[id]['slider'].adjust();
				//}
			};
		}).trigger('resizeEnd');
	}

});

/****************************************************************************************************************************************************************************************
FUNCTIONS
****************************************************************************************************************************************************************************************/

// Scrollspy
(function ($) {
	$.fn.scrollSpy = function (settings) {
		settings = jQuery.extend({
			showSpeed: 100,
			callback: function () { }
		}, settings);

		var topMenu = $(this);

		if (topMenu.length) {
			// Cache selectors
			var lastId,
				topMenuHeight = topMenu.outerHeight(),

				// All list items
				menuItems = topMenu.find("a"),

				// Anchors corresponding to menu items
				scrollItems = menuItems.map(function () {
					var item = $($(this).attr("href"));
					if (item.length) { return item; }
				});

			// Bind to scroll
			$(window).bind('load scroll', function () {
				// Get container scroll position
				var fromTop = $(this).scrollTop() + topMenuHeight + 50;

				// Get id of current scroll item
				var cur = scrollItems.map(function () {
					if ($(this).offset().top < fromTop)
						return this;
				});
				// Get the id of the current element
				cur = cur[cur.length - 1];
				var id = cur && cur.length ? cur[0].id : "";

				if (lastId !== id) {
					lastId = id;
					// Set/remove active class
					menuItems
						.parent().removeClass("active")
						.end().filter("[href*=\\#" + id + "]").parent().addClass("active");
				}
			});
		}
	}
})(jQuery);

// Univerzální show/hide
(function ($) {
	$.fn.toggler = function (settings) {

		settings = jQuery.extend({
			showSpeed: 100,
			hideSpeed: 100,
			hideTrigger: false,
			slide: false,
			scrollToContent: false,
			offsetScroll: 0,
			hideOnDocumentClick: false,
			documentClickElement: '',
			hideOnEscape: false
		}, settings);

		$(this).each(function (index, value) {

			var trigger = $(this);
			var content = $('#' + trigger.data('content'));

			// Radio nebo checkbox
			if (trigger.is(':radio') || trigger.is(':checkbox')) {
				var name = trigger.attr('name');

				$('[name=' + name + ']').on('change', function () {
					content.hide();
				});
				trigger.on('change', function () {
					if ($(this).is(':checked')) {
						content.fadeIn(settings.showSpeed);
					}
					else {
						content.hide();
					}
				});
				$(window).on('load', function () {
					if (trigger.is(':checked')) {
						content.fadeIn(settings.showSpeed);
					}
				});
			}
			// Standardní element
			else {
				function scrollToContent() {
					var offset = 0;
					if (settings.offsetScroll) {
						offset = settings.offsetScroll
					}
					$("html:not(:animated),body:not(:animated)").animate({ scrollTop: $(content).offset().top + offset }, 500);
				}

				function scrollToTrigger() {
					var offset = 0;
					if (settings.offsetScroll) {
						offset = settings.offsetScroll + 200
					}
					$("html:not(:animated),body:not(:animated)").animate({ scrollTop: $('[data-content=' + trigger.attr('data-content') + ']').offset().top + offset }, 500);
				}

				trigger.on('click', function (event) {
					if (content.is(':hidden')) {
						if (settings.slide) {
							content.slideDown(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								};
							});
						}
						else {
							content.fadeIn(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								};
							});
						}

						$(this).addClass('active').find('.text').text($(this).attr('data-less'));

						if (settings.hideTrigger) {
							trigger.hide();
						}
					}
					else {
						if (settings.slide) {
							content.slideUp(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								};
							});
						}
						else {
							content.fadeOut(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								};
							});
						}
						$(this).removeClass('active').find('.text').text($(this).attr('data-more'));

						if (settings.hideTrigger) {
							$('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
						}
					}
					event.preventDefault();
					//return false;
				});
			}
			// Hide on document click
			if (settings.hideOnDocumentClick || trigger.attr('data-hideonclick') == 'true') {
				$(document).on('click', function (e) {
					if ((!$(settings.documentClickElement).is(e.target) && $(settings.documentClickElement).has(e.target).length === 0) && (!trigger.is(e.target) && trigger.has(e.target).length === 0)) {
						content.fadeOut();
						trigger.removeClass('active');
					}
				});
			}
			// Escape button
			if (settings.hideOnEscape) {
				$(document).keyup(function (e) {
					if (e.keyCode === 27) {
						content.fadeOut();
						trigger.removeClass('active');
					}
				});
			}
		});
	}
})(jQuery);

// Plynulý scroll ke kotvě
(function ($) {
	$.fn.scrollToAnchor = function (settings) {

		settings = jQuery.extend({
			speed: 750
		}, settings);

		return this.each(function () {
			var caller = this
			$(caller).click(function (event) {
				event.preventDefault()
				var locationHref = window.location.href
				var elementClick = $(caller).attr("href")

				var destination = $(elementClick).offset().top;

				$("html:not(:animated),body:not(:animated)").animate({
					scrollTop: destination
				}, {
					duration: settings.speed,
					//easing: "easeInOutQuint",
					complete: function () {
						history.pushState(null, null, elementClick);
					}
				}
				);
				return false;
			});
		});
	}
})(jQuery);

// Lite tabs
(function ($) {
	$.fn.liteTabs = function (options) {
		return this.each(function () {
			var defaults = {
				fadeIn: false,
				height: 'auto',
				hideHash: false,
				hashTag: true,
				selectedTab: 1,
				width: 500
			};

			// Merge defaults with options in new settings object
			var settings = $.extend({}, defaults, options);

			// Define key variables
			var $this = $(this);
			var $ul = $this.find('.tab-nav ul');
			var $tab = $ul.find('a');
			var $div = $this.find('.tab-content').first().find('.tab');

			// Tab click
			$tab.click(function (e) {
				var hash = this.hash;
				var id = hash.replace('#', '');
				var selectedPanel = $div.filter('[id="' + id + '"]');
				var filterHash = $div.removeClass('selected').filter('[id="' + id + '"]');

				// defaults: add selected class to tab
				$tab.removeClass('selected').filter(this).addClass('selected');

				// Rodičovská záložka zůstane otevřená při kliku na vnořené záložky v záložce
				var parentId = $tab.filter(this).closest('.tab').attr('id');

				$('.tab[id="' + parentId + '"]').addClass('selected');
				$('.tab-nav a[href="' + parentId + '"]').addClass('selected');

				if ($tab.parents('.tabs').length > 1) {
					e.preventDefault();
				}

				// Show panel
				filterHash.addClass('selected');

				// Option: hide hash change
				if (settings.hideHash) {
					e.preventDefault();
				}

				setTimeout(() => {
					$(window).resize();
				}, 0);
				return false;
			});

			// Option: set selected tab
			if (settings.selectedTab) {
				$tab.eq(settings.selectedTab - 1).addClass('selected');
				$div.eq(settings.selectedTab - 1).addClass('selected');
			}

			// If hash tag..
			var hash = window.location.hash.substring(1);
			$('[href="#' + hash + '"]').click();
		});
	};
})(jQuery);
