$(function () {
	$('body').on('change', 'select.ajax', function (event) {
		event.preventDefault();
		const form = $(this).closest('form');
		form.submit();
	});

	$('body').on('click', 'a.ajax', function (event) {
		event.preventDefault();
		window.history.pushState({href: $(this).attr('href')}, '', $(this).attr('href'));
		ajaxCall(null, null, $(this).attr('href'), this);
	});

	$('body').on('change', 'input:radio.ajax', function (event) {
		event.preventDefault();
		const form = $(this).closest('form');
		form.submit();
	});

	function ajaxCall(form, formData, customUrl, action) {
		if (customUrl) {
			$.ajax(customUrl, {
				type: 'get',
				data: {
					'ts': Date.now()
				},
				beforeSend: function () {
					$('.ajax-loader').show();
				},
				complete: function () {
					$('.ajax-loader').hide();
				},
				success: function (data) {
					jQuery.each(data.snippets, function (snippet, currentValue) {
						$('#' + snippet).html(currentValue);
					});
					$(window).trigger('ajaxSwitch');
					if ($(action).attr('data-scrollTo')) {
						$("html:not(:animated),body:not(:animated)").animate({
									scrollTop: $('#' + $(action).attr('data-scrollTo')).offset().top
								}, {
									duration: 1000,
									complete: function () {
									}
								}
						);
					}
				},
				error: function () {
					console.log('error');
				}
			});
		} else if (form) {
			$.ajax(form.attr('action'), {
				type: form.attr('method'),
				data: formData,
				beforeSend: function () {
					$('.ajax-loader').show();
				},
				complete: function () {
					$('.ajax-loader').hide();
				},
				success: function (data) {
					jQuery.each(data.snippets, function (snippet, currentValue) {
						$('#' + snippet).html(currentValue);
					});
					$(window).trigger('ajaxSwitch');
				},
				error: function () {
					console.log('error');
				}
			});
		}
	}

	function serializeFormData(form) {
		const formData = form.serializeArray().reduce(function (obj, item) {
			obj[item.name] = item.value;
			return obj;
		}, {});

		return formData;
	};

	function getQueryString(form, formData) {
		return form.attr('action') + (Object.keys(formData).length ? '?' : '') + Object.keys(formData)
				.map(key => `${key}=${formData[key]}`)
				.join('&');
	}

	$(window).on('popstate', function (e) {
		document.location.href = document.location.href;
	});
});