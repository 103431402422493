$(function () {
	var typingTimer;                //timer identifier
	var doneTypingInterval = 400;  //time in ms (5 seconds)

	$('body').on('keyup', 'input[name=q].ajax', function (event) {
		event.preventDefault();
		const form = $(this).closest('form');
		const url = form.attr('action');
		const searchPhrase = $(this).val();
		const targetDiv = $(this).attr('data-target-div');
		if(this.value.length >= 3) {
			clearTimeout(typingTimer);
			if ($(this).val()) {
				typingTimer = setTimeout(autoComplete, doneTypingInterval, url, searchPhrase, targetDiv);
			}
		}
	});

	function autoComplete(url, q, targetDiv) {
		$.ajax(url, {
			type: 'GET',
			data: {
				q: q,
				ts: Date.now()
			},
			success: function(data) {
				$('#' + targetDiv).html(data.content);
				if (data.isResults) {
					$('[data-purpose="dropdown-search"]').addClass('active');
				}
			},
			error: function() {
				console.log('error');
			}
		});
	}
});